import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import AppWrapper from '../components/templates/AppWrapper';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Faqs from '../pages/Faqs';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import Compliance from '../pages/Compliance';
import BNPL from '../pages/BNPL';
import SCF from '../pages/SCF';
import VendorFinancing from '../pages/VendorFinancing';
import Media from '../pages/Media';
import Partners from '../pages/Partners';
import Login from '../pages/Login';
import NotFound from '../pages/NotFound';
import News from '../pages/News';
import Notices from '../pages/compliance/Notices.jsx';
import Policies from '../pages/compliance/Policies';
import Return from '../pages/compliance/Return';

const AppRoutes = () => {
    return (
        <BrowserRouter>
            <AppWrapper>
                <Routes>
                    <Route
                        index
                        element={<Home />}
                    />
                    <Route
                        path='login'
                        element={<Login />}
                    />
                    <Route
                        path='partners/:type'
                        element={<Partners />}
                    />
                    <Route
                        path='partners/:type/:url'
                        element={<Partners />}
                    />
                    <Route
                        path='saral-solutions/saral-pay-later'
                        element={<BNPL />}
                    />
                    <Route
                        path='saral-solutions/saral-vendor-finance'
                        element={<VendorFinancing />}
                    />
                    <Route
                        path='saral-solutions/corporate-working-captial'
                        element={<SCF />}
                    />
                    <Route
                        path='frequently-asked-questions'
                        element={<Faqs />}
                    />
                    <Route
                        path='about'
                        element={<About />}
                    />
                    <Route
                        path='in-the-media'
                        element={<Media />}
                    />
                    <Route
                        path='contact'
                        element={<Contact />}
                    />
                    <Route
                        path='terms-and-conditions'
                        element={<Terms />}
                    />
                    <Route
                        path='privacy-policy'
                        element={<Privacy />}
                    />
                    <Route
                        path='regulatory-and-compliance'
                        element={<Compliance />}
                    />
                    <Route
                        path='regulatory-and-compliance/notices'
                        element={<Notices />}
                    />
                    <Route
                        path='regulatory-and-compliance/policies'
                        element={<Policies />}
                    />
                    <Route
                        path='regulatory-and-compliance/returns'
                        element={<Return />}
                    />
                    <Route
                        path='news'
                        element={<News />}
                    />
                    <Route
                        path='*'
                        element={<NotFound />}
                    />
                </Routes>
            </AppWrapper>
        </BrowserRouter>
    );
};

export default AppRoutes;
