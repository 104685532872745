import React from 'react';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import Image from '../components/atoms/Image';
import Paragraph from '../components/atoms/Paragraph';
import StaticBanner from '../components/organisms/StaticBanner';
import Stats from '../components/molecules/Stats';
import Section from '../components/organisms/Section';
import { Link } from 'react-router-dom';
import PartnersScroll from '../components/templates/PartnersScroll';
import { STATS } from '../constants/stats';
import { DICT } from '../constants/dict';

const SCF = () => {
    return (
        <>
            <StaticBanner size='none'>
                <div className='hero-text'>
                    <Heading type='h1'>Corporate Working Capital Lines</Heading>
                    <Paragraph>
                        Caters to the immediate working capital requirements of Anchor Partners.
                    </Paragraph>
                </div>
                <div className='hero-stats'>
                    <Stats
                        items={[
                            {
                                icon: 'community-line',
                                value: STATS.scf.anchors,
                                desc: DICT.current_anchors,
                            },
                            {
                                icon: 'user-line',
                                value: STATS.scf.borrowers,
                                desc: DICT.current_borrowers,
                            },
                            {
                                icon: 'article-line',
                                value: STATS.scf.invoices,
                                desc: DICT.current_invoices,
                            },
                            {
                                icon: 'send-plane-2-line',
                                value: STATS.scf.disbursed,
                                desc: DICT.total_amount,
                                currency: true,
                            },
                            {
                                icon: 'cash-line',
                                value: STATS.scf.aum,
                                desc: DICT.current_aum,
                                currency: true,
                            },
                        ]}
                    />
                </div>
                <small className='note-text'>* since inception</small>
                {/* <PartnersScroll
                    url='saral-supply-chain-credit'
                    type='live'
                /> */}
            </StaticBanner>
            <div className='separator'></div>
            <Container className='text-center'>
                <Heading>Who can benefit from Corporate Working Capital?</Heading>
                <Heading type='h4'>This product can benefit Anchors.</Heading>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does Corporate Working Capital work? ',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'ul',
                                            config: {
                                                items: [
                                                    'The Anchor company serves as the borrower in Supply Chain Credit.',
                                                    'The borrower can use this credit to finance its payables or receivables.',
                                                    'In payable finance, funds are disbursed to the Vendor of the borrower company.',
                                                    "In receivable finance, funds are disbursed into the borrower company's bank account.",
                                                    'In both cases, repayment is received from the borrower company.',
                                                ],
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/1.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
            <div className='separator'></div>
            <Container>
                <Section
                    config={{
                        align: 'center',
                        items: [
                            {
                                col: 6,
                                colXs: 12,
                                type: 'section',
                                align: 'center',
                                config: {
                                    items: [
                                        {
                                            type: 'heading',
                                            config: {
                                                text: 'How does SaralSCF expedite the Corporate Working Capital financing process?',
                                                className: 'mb-10',
                                            },
                                        },
                                        {
                                            type: 'paragraph',
                                            config: {
                                                text: 'SaralSCF offers a robust solution for handling multiple invoices of smaller amounts. It enables the anchor/ borrower to get multiple invoices financed across different vendors simultaneously. Moreover, SaralSCF also has a mechanism to disburse invoices partly based on the requirement from the anchor/borrower.',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                col: 6,
                                colXs: 12,
                                type: 'image',
                                config: {
                                    src: '/img/products/2.png',
                                },
                            },
                        ],
                    }}></Section>
            </Container>
        </>
    );
};

export default SCF;
