export const DICT = {
    current_anchors: 'Current Anchor Partners',
    current_borrowers: 'Current Borrowers',
    current_invoices: 'Current Outstanding Invoices',
    current_amount: 'Current Amount Disbursed',
    current_aum: 'Current AUM',

    total_anchors: 'Anchor Partners*',
    total_borrowers: 'Borrowers*',
    total_invoices: 'Invoices Disbursed*',
    total_amount: 'Amount Disbursed*',
    total_aum: 'AUM',
};
