import React from 'react';
import { useParams } from 'react-router-dom';
import StaticBanner from '../components/organisms/StaticBanner';
import Container from '../components/atoms/Container';
import Heading from '../components/atoms/Heading';
import { beautify } from '../helpers/str';
import { PARTNERS } from '../constants/partners';

const Partners = () => {
    const { url, type } = useParams();
    const partners = PARTNERS;

    const listPartners = (partnerType, partnerUrl) => {
        return (
            <>
                {partners[partnerUrl][partnerType].map((partner) => {
                    return (
                        <div
                            className='partner'
                            style={{ backgroundColor: partner.color || '#fff' }}>
                            <img
                                src={`/img/partners/${partner.logo}`}
                                alt=''
                            />
                        </div>
                    );
                })}
            </>
        );
    };

    return (
        <div>
            <StaticBanner size='xs'>
                <Container>
                    <div className='hero-text'>
                        <Heading type='h2'>
                            {beautify(type)} {beautify(url)} Partners
                        </Heading>
                    </div>
                </Container>
            </StaticBanner>
            <Container>
                <div className='partners'>
                    {url ? (
                        listPartners(type, url)
                    ) : (
                        <>
                            {[
                                'saral-pay-later',
                                'saral-vendor-finance',
                                'corporate-working-captial-lines',
                            ].map((key) => {
                                return listPartners(type, key);
                            })}
                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default Partners;
