export const STATS = {
    // Home page
    all: {
        anchors: 137,
        borrowers: 17387,
        invoices: 329859,
        disbursed: 26627635004.12,
        aum: 2858340103.0,
    },
    // SaralPay Later
    bnpl: {
        anchors: 24,
        borrowers: 3080,
        invoices: 20666,
        disbursed: 9089556414.78,
        aum: 459845641.0,
    },
    // Vendor Finance
    vendor: {
        anchors: 8,
        borrowers: 93,
        invoices: 779,
        disbursed: 6381980532.34,
        aum: 948588179.0,
    },
    // Supplychain finance
    scf: {
        anchors: 21,
        borrowers: 21,
        invoices: 719,
        disbursed: 11156098057.0,
        aum: 1449906283.0,
    },
};
